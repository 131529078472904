<template>
<div class="maincontent">
   <div class="container-fluid" >
    <div class="row">
            <div class="col-12 text-center" style="padding-top:10px">
                        <h2>Some of Our Esteemed Clients</h2>
              <Carousel :autoplay="3000" :transition="1000" :items-to-show="5" :itemsToScroll="3" :wrap-around="true" class="ClientCarousel desktop">
                <Slide v-for="slide in slider" :key="slide.id"  class="clientslide">
                  <div class="carousel__item slider">
                    <div class="carousel-container">
                      <Image :fpath="slide.path" class="clientimg"/>
                       </div>
                  </div>
                </Slide>
                <template #addons>
                <Navigation />
                  <Pagination />
                </template>
              </Carousel>

              <Carousel :autoplay="3000" :transition="1000" :items-to-show="3" :itemsToScroll="3" :wrap-around="true" class="ClientCarousel mobile">
                <Slide v-for="slide in slider" :key="slide.id"  class="clientslide">
                  <div class="carousel__item slider">
                    <div class="carousel-container">
                      <Image :fpath="slide.path" class="clientimg"/>
                       </div>
                  </div>
                </Slide>
                <template #addons>
                <Navigation />
                  <Pagination />
                </template>
              </Carousel>
          </div>
          
     </div>
 </div>
    </div>
</template>

<script>

import { defineComponent } from 'vue'
import { Carousel, Navigation, Pagination, Slide } from 'vue3-carousel'
import Image from '@/components/Image.vue'
import 'vue3-carousel/dist/carousel.css'

export default defineComponent({
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
    Image
  },
  data() {
   return { slider: [{ id: 1, path: 'clients/a5.png', },{ id: 1, path: 'clients/a6.png', },{ id: 1, path: 'clients/a7.jpg', },{ id: 1, path: 'clients/a8.png', },{ id: 1, path: 'clients/a13.png', },{ id: 1, path: 'clients/a14.png', },{ id: 1, path: 'clients/a15.png', },{ id: 1, path: 'clients/a16.png', },{ id: 1, path: 'clients/a17.png', },{ id: 1, path: 'clients/a18.png', },{ id: 3, path: 'clients/a3.png', }, { id: 1, path: 'clients/a4.png', }] }
   },
   settings: {
      snapAlign: 'center',
    },
});
</script>
<style >
.carousel__pagination-button--active {
    background-color: #72287a;
}
.carousel__pagination-button {
    width: 12px;
    height: 12px;
    border-radius: 50%;
}
  .carousel__item {
      min-height: 100px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
  }
  .slider{
      border:1px solid #666;
  }
@media only screen and (min-width: 650px) and (max-width: 2561px) {
.ClientCarousel.mobile{
    display:none;
  }

}
@media only screen and (min-width: 0px) and (max-width: 649px) {
.ClientCarousel.desktop{
    display:none;
  }
}
@media only screen and (min-width: 0px) and (max-width: 992px) {
  .carousel-container> .clientimg{
    width:100% !important;
  }
  
  button.carousel__prev {
    left: 0px !important;
    opacity: 40%;
}
button.carousel__next{
  opacity: 40%;
}
button.carousel__prev:hover {
    opacity: 100%;
}
button.carousel__next:hover{
  opacity: 100%;
}
}
  .carousel-container> .clientimg{
    width:70%;
  }
  li.clientslide{
      padding:10px;
  }
  .carousel__prev:hover{
      background-color:#ed1d8d;
  }
  .carousel__next:hover{
      background-color:#ed1d8d;
  }
  .carousel__prev{
      box-sizing: content-box;
      top: 40%;
      background-color: #72287a;
      left: 20px;
      border: 3px solid;
      transform: translate(50%, -50%);
  }
  .carousel__next {
      box-sizing: content-box;
      background-color: #72287a;
      top: 40%;
      right: 40px !important;
      border: 3px solid;
      transform: translate(50%, -50%);
  }
  .ClientCarousel {
      width: 100%;
      padding:30px 0px;
  }
  .maincontent{
      padding-top: 10px;
  }

</style>
